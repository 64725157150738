import React, { FC } from 'react';
import { useField as useFormikField } from 'formik';
import {
  Dropdown as TPADropdown,
  DropdownOptionProps,
  DropdownTheme,
} from 'wix-ui-tpa/cssVars';

import { Field, FieldWrapper } from '../../../../../../types';
import { DataHook } from '../../../../../../constants/DataHook';
import { useFieldLabel } from '../../../../../../hooks';
import { FieldLabel } from '../../FieldLabel';
import { FieldPrivacyIcon } from '../../FieldPrivacyIcon';
import { classes, st } from './Dropdown.st.css';

interface DropdownFieldProps extends Omit<FieldWrapper, 'theme'> {
  options: DropdownOptionProps[];
  theme?: DropdownTheme;
}

export const DropdownField: FC<DropdownFieldProps> = ({
  formikFieldSelector,
  theme,
  options,
}) => {
  const { getFieldLabel } = useFieldLabel();
  const [formikField, , helpers] = useFormikField<Field | null>(
    formikFieldSelector,
  );

  const field = formikField.value;
  const isFieldThemeBox = theme === DropdownTheme.Box;

  if (!field) {
    return null;
  }

  const { required, infoTooltipText, value, key, id } = field;

  return (
    <div data-hook={key}>
      <FieldLabel
        text={getFieldLabel(field)}
        htmlFor={id}
        isFieldRequired={required}
        infoTooltipText={infoTooltipText}
      />
      <FieldPrivacyIcon
        formikFieldSelector={formikFieldSelector}
        data-hook={DataHook.FieldPrivacyIcon}
      >
        <TPADropdown
          theme={theme}
          options={options}
          className={st(classes.field, { box: isFieldThemeBox })}
          data-hook={DataHook.TextField}
          placeholder=""
          initialSelectedId={
            options.length && value.selectedId ? value.selectedId : ' '
          }
          onChange={(event) => {
            helpers.setValue({
              ...field,
              value: { ...value, selectedId: event.id },
            });
          }}
        />
      </FieldPrivacyIcon>
    </div>
  );
};
