import React, { FC, PropsWithChildren } from 'react';
import { Form } from 'formik';
import {
  Button,
  ButtonPriority,
  StatesButton,
  StatesButtonStates,
  InputDialog,
  InputDialogProps,
  ThreeDotsLoader,
} from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../../../constants/DataHook';
import { classes } from './LoginInfoDialog.st.css';

export interface LoginInfoDialogProps
  extends Omit<InputDialogProps, 'primaryButton'> {
  state: StatesButtonStates.IDLE | StatesButtonStates.IN_PROGRESS;
  primaryText: string;
  primaryOnClick(): void;
  secondaryText: string;
}

export const LoginInfoDialog: FC<PropsWithChildren<LoginInfoDialogProps>> = ({
  children,
  primaryText,
  primaryOnClick,
  secondaryText,
  state,
  ...props
}) => {
  return (
    <InputDialog
      {...props}
      forceBWTheme
      withoutDivider
      className={classes.dialog}
      customFooter={
        <div className={classes.footer}>
          <Button
            upgrade
            className={classes.secondary}
            data-hook={DataHook.LoginInfoDialogSecondaryButton}
            priority={ButtonPriority.basicSecondary}
            onClick={props.onClose}
          >
            {secondaryText}
          </Button>
          <StatesButton
            data-hook={DataHook.LoginInfoDialogPrimaryButton}
            state={state}
            upgrade
            className={classes.primary}
            onClick={primaryOnClick}
            idleContent={primaryText}
            inProgressContent={
              <ThreeDotsLoader
                className={classes.loader}
                data-hook={DataHook.LoginInfoDialogLoader}
              />
            }
          />
        </div>
      }
    >
      <Form className={classes.wrapper}>{children}</Form>
    </InputDialog>
  );
};
