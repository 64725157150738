import React, { FC, useState, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { TextField, TextFieldProps } from 'wix-ui-tpa/cssVars';

import { classes } from './LoginInfoTextField.st.css';

export interface LoginInfoTextFieldProps
  extends Omit<
    TextFieldProps,
    | 'value'
    | 'newErrorMessage'
    | 'className'
    | 'errorMessage'
    | 'error'
    | 'onChange'
  > {
  name: string;
  bottom?: React.ReactNode;
}

export const LoginInfoTextField: FC<LoginInfoTextFieldProps> = ({
  name,
  onFocus,
  onBlur,
  bottom,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false);
  const { setFieldValue, errors, touched } =
    useFormikContext<Record<string, any>>();
  const error = errors[name] as string;

  const setFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus?.(event);
    },
    [onFocus],
  );

  const setBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      onBlur?.(event);
    },
    [onBlur],
  );

  return (
    <div className={classes.root}>
      <TextField
        {...props}
        name={name}
        onChange={(event) => setFieldValue(name, event.target.value)}
        onFocus={setFocus}
        onBlur={setBlur}
        error={isFocused || !touched[name] ? undefined : Boolean(error)}
        errorMessage={error}
        newErrorMessage
        className={classes.monochrome}
      />
      {bottom}
    </div>
  );
};
