import React, { FC, useCallback, useState } from 'react';
import {
  SectionNotification,
  SectionNotificationType,
  StatesButtonStates,
} from 'wix-ui-tpa/cssVars';
import { Formik, Field } from 'formik';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Alert } from '@wix/wix-ui-icons-common/on-stage';

import { ModalType } from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { LoginInfoTextField } from '../../common/LoginInfoTextField';
import { initialValues } from './constants';
import { useChangeEmailValidation } from './useChangeEmailValidation';
import {
  LoginInfoDialog,
  LoginInfoDialogProps,
} from '../../common/LoginInfoDialog/LoginInfoDialog';
import { LoginInfoTextButton } from '../../common/LoginInfoTextButton';

export const ChangeLoginEmailModal: FC<ModalType> = ({ onClose }) => {
  const { t } = useTranslation();
  const validate = useChangeEmailValidation();
  const [submitState, setSubmitState] = useState<LoginInfoDialogProps['state']>(
    StatesButtonStates.IDLE,
  );

  const changeEmail = useCallback(() => {
    setSubmitState(StatesButtonStates.IN_PROGRESS);
  }, []);

  const recoverPassword = useCallback(() => {}, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={changeEmail}
      validate={validate}
    >
      {({ submitForm }) => (
        <LoginInfoDialog
          state={submitState}
          isOpen
          data-hook={DataHook.ChangeEmailModal}
          title={t('app.widget.modals.change-email.title')}
          subtitle={t('app.widget.modals.change-email.subtitle')}
          onClose={onClose}
          primaryText={t('app.widget.modals.change-email.change-button')}
          primaryOnClick={submitForm}
          secondaryText={t('app.widget.modals.change-email.cancel-button')}
        >
          <SectionNotification type={SectionNotificationType.alert}>
            <SectionNotification.Icon
              icon={<Alert color="#FFB600" size={24} />}
            />
            <SectionNotification.Text>
              {t('app.widget.modals.change-email.warning')}
            </SectionNotification.Text>
          </SectionNotification>

          <Field
            as={LoginInfoTextField}
            name="newEmail"
            type="email"
            label={t('app.widget.modals.change-email.new-email.label')}
            data-hook={DataHook.ChangeEmailNewInput}
          />

          <Field
            as={LoginInfoTextField}
            name="confirmEmail"
            type="email"
            label={t('app.widget.modals.change-email.confirm-email.label')}
            data-hook={DataHook.ChangeEmailConfirmInput}
          />

          <Field
            as={LoginInfoTextField}
            type="password"
            name="password"
            label={t('app.widget.modals.change-email.password.label')}
            data-hook={DataHook.ChangeEmailPasswordInput}
            bottom={
              <div>
                <LoginInfoTextButton
                  onClick={recoverPassword}
                  data-hook={DataHook.ChangeEmailForgotPassword}
                >
                  {t('app.widget.modals.change-email.password.action')}
                </LoginInfoTextButton>
              </div>
            }
          />
        </LoginInfoDialog>
      )}
    </Formik>
  );
};
