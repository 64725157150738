import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import settingsParams from '../../settingsParams';
import { SectionHeader } from '../common/SectionHeader';
import { CTAGroup } from '../common/CTAGroup';
import { classes, st } from './Header.st.css';

export const Header: FC = () => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <div>
        <SectionHeader
          className={classes.header}
          sectionType="page"
          title={settings.get(settingsParams.pageSectionTitle)}
          subtitle={settings.get(settingsParams.pageSectionSubtitle)}
        />
      </div>
      <div>
        <CTAGroup />
      </div>
    </div>
  );
};
