import { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { emailSchema } from './constants';
import { ChangeEmailValues } from './types';

const isEmail = (value: string) => emailSchema.isValidSync(value);

export function useChangeEmailValidation() {
  const { t } = useTranslation();

  return useCallback(
    (values: ChangeEmailValues) => {
      const errors: FormikErrors<ChangeEmailValues> = {};
      if (values.newEmail.length <= 0) {
        errors.newEmail = t('app.widget.fields.validation.required');
      } else if (!isEmail(values.newEmail)) {
        errors.newEmail = t(
          'app.widget.modals.change-email.new-email.error-invalid',
        );
      }
      if (values.newEmail !== values.confirmEmail) {
        errors.confirmEmail = t(
          'app.widget.modals.change-email.confirm-email.error-match',
        );
      }
      if (values.password.length <= 0) {
        errors.password = t('app.widget.fields.validation.required');
      }

      return errors;
    },
    [t],
  );
}
