import React, { FC, useCallback, useState } from 'react';
import { Text, TextPriority, StatesButtonStates } from 'wix-ui-tpa/cssVars';
import { Formik, Field } from 'formik';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { ModalType } from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { LoginInfoTextField } from '../../common/LoginInfoTextField';
import { initialValues } from './constants';
import { useChangePasswordValidation } from './useChangePasswordValidation';
import {
  LoginInfoDialog,
  LoginInfoDialogProps,
} from '../../common/LoginInfoDialog/LoginInfoDialog';
import { classes } from './ChangeLoginPasswordModal.st.css';
import { LoginInfoTextButton } from '../../common/LoginInfoTextButton';

export const ChangeLoginPasswordModal: FC<ModalType> = ({ onClose }) => {
  const { t } = useTranslation();
  const validate = useChangePasswordValidation();
  const [submitState, setSubmitState] = useState<LoginInfoDialogProps['state']>(
    StatesButtonStates.IDLE,
  );

  const changePassword = useCallback(
    () => setSubmitState(StatesButtonStates.IN_PROGRESS),
    [],
  );

  const recoverPassword = useCallback(() => {}, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={changePassword}
      validate={validate}
    >
      {({ submitForm }) => {
        return (
          <LoginInfoDialog
            state={submitState}
            data-hook={DataHook.ChangePasswordModal}
            isOpen
            withoutDivider
            title={t('app.widget.modals.change-password.title')}
            subtitle={t('app.widget.modals.change-password.subtitle')}
            onClose={onClose}
            primaryOnClick={submitForm}
            primaryText={t('app.widget.modals.change-password.change-button')}
            secondaryText={t('app.widget.modals.change-password.cancel-button')}
          >
            <Field
              as={LoginInfoTextField}
              type="password"
              data-hook={DataHook.ChangePasswordCurrentInput}
              label={t('app.widget.modals.change-password.password.label')}
              name="currentPassword"
              bottom={
                <div>
                  <LoginInfoTextButton
                    onClick={recoverPassword}
                    data-hook={DataHook.ChangePasswordForgotPassword}
                  >
                    {t('app.widget.modals.change-password.password.action')}
                  </LoginInfoTextButton>
                </div>
              }
            />

            <Field
              as={LoginInfoTextField}
              type="password"
              label={t('app.widget.modals.change-password.new-password.label')}
              data-hook={DataHook.ChangePasswordNewInput}
              name="newPassword"
              bottom={
                <Text
                  tagName="div"
                  priority={TextPriority.secondary}
                  className={classes.helpText}
                >
                  {t('app.widget.modals.change-password.new-password.help')}
                </Text>
              }
            />

            <Field
              type="password"
              as={LoginInfoTextField}
              label={t(
                'app.widget.modals.change-password.confirm-password.label',
              )}
              data-hook={DataHook.ChangePasswordConfirmInput}
              name="confirmPassword"
            />
          </LoginInfoDialog>
        );
      }}
    </Formik>
  );
};
