import { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { ChangePasswordValues } from './types';

export function useChangePasswordValidation() {
  const { t } = useTranslation();
  return useCallback(
    (values: ChangePasswordValues) => {
      const errors: FormikErrors<ChangePasswordValues> = {};
      if (values.newPassword.length < 5) {
        errors.newPassword = t(
          'app.widget.modals.change-password.new-password.error-min-5-chars',
        );
      }
      if (values.confirmPassword !== values.newPassword) {
        errors.confirmPassword = t(
          'app.widget.modals.change-password.confirm-password.error-match',
        );
      }
      if (values.currentPassword.length <= 0) {
        errors.currentPassword = t('app.widget.fields.validation.required');
      }
      return errors;
    },
    [t],
  );
}
