import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { classes, st } from './SectionHeader.st.css';
import { DataHook } from '../../../../../constants/DataHook';

type Props = {
  title: string | JSX.Element;
  subtitle?: string;
  className?: string;
  sectionType?: 'section' | 'page';
};

export const SectionHeader: FC<Props> = ({
  title,
  subtitle,
  className,
  sectionType = 'section',
}) => {
  const { isMobile } = useEnvironment();

  return (
    <div className={st(classes.root, { mobile: isMobile }, className)}>
      <Text
        className={st(classes.title, {
          page: sectionType === 'page',
          section: sectionType === 'section',
        })}
        data-hook={DataHook.SectionTitle}
        tagName="h2"
        id="members-area-page-title"
      >
        {title}
      </Text>
      {subtitle && (
        <div className={classes.subtitleWrapper}>
          <Text className={classes.subtitle}>{subtitle}</Text>
        </div>
      )}
    </div>
  );
};
